import {
 Alert,
 Button,
 ColumnLayout,
 ExpandableSection,
 FormField,
 FormSection,
 Input,
 Select,
 Spinner,
 Checkbox,
 Toggle
} from "@amzn/awsui-components-react";
import { get, isEmpty } from "lodash";
import {
 testMappingSelector,
 addMapping,
 resetMapping
} from "../../../../../newSystem/stores/newRun/mappingSlice";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { API_STATUS, UNNAMED_DEVICE } from "../../../../constants";
import {
 deviceAccSelector,
 labConfigSelector,
 setSystemLabConfig,
 reset
} from "../../../../stores/newRun/acm/labConfigSlice";
import {
 handleLabSelection,
 setupInitialLabConfig,
} from "../../../../stores/newRun/acm/testCreationMiddleware";
import { labsSelector } from "../../../../stores/newRun/labSlice";
import {
 piSelector,
 updatePiWithCustomNames,
} from "../../../../stores/newRun/piSlice";
import { testInitSelector } from "../../../../stores/newRun/testInitSlice";
import { AppConstants, Controller } from '@amzn/amazon-devicequalification-ui-components/dist/index.js'

export default () => {
 // Default behaviour is to show user-lab selection form.
 const [showSystemLabForm, setShowSystemLabForm] = useState(false);
 const {
   status: labFetchStatus,
   labs,
   error: labFetchErr,
 } = useSelector(labsSelector);
 const { labInfo, error: labValidationError } = useSelector(labConfigSelector);

 const dispatch = useDispatch();
 useEffect(() => {
   dispatch(reset());
   if (!showSystemLabForm) {
     dispatch(setupInitialLabConfig(labInfo.id));
   } else {
     //We should also reset mapping in case user made lab selection and then clicked on edit button on preview
     //page to change their selection to system lab
     dispatch(resetMapping());
   }
 }, [showSystemLabForm]);

 const handleLabChange = (e) => {
   const newLabInfo = e.detail.selectedOption.meta;
   dispatch(handleLabSelection(newLabInfo));
 };

 const handleDeviceModelChange = (e) => {
     const deviceModelName = e.detail.value;
     dispatch(setSystemLabConfig({deviceModelName}));
 };

 let labOptions = [];
 if (labFetchStatus === API_STATUS.SUCCESS) {
   labOptions = labs.map((lab) => ({
     id: lab.id,
     label: lab.name,
     meta: lab,
     disabled: lab.lock === 1,
   }));
 }

 let selectedOption = {};
 if (labInfo.id) {
   selectedOption = { id: labInfo.id, label: labInfo.name };
 }

 const labInfoError = get(labValidationError, "labInfo");
 const accountInfoError = get(labValidationError, "account");
 let labFetchError;
 if (labFetchStatus === API_STATUS.ERROR) {
   labFetchError = (
     <Alert type="error" key={1} className="awsui-util-mt-s awsui-util-mb-s">
       <div>{labFetchErr}</div>
     </Alert>
   );
 }

 let accountValidationView;
 if (accountInfoError) {
   accountValidationView = (
     <Alert type="error" key={3} className="awsui-util-mt-s awsui-util-mb-s">
       <div>{accountInfoError}</div>
     </Alert>
   );
 }

 const toggleLabSelectionForm = () => {
     // Simply inverts the current value of the toggle which triggers useEffect causing page refresh.
     setShowSystemLabForm(!showSystemLabForm);
 };

 return (
   <>
     <FormSection header={<FormattedMessage id="LAB_INFO" />}>
       <ColumnLayout>
           <Toggle onChange={toggleLabSelectionForm} description={<FormattedMessage id="LAB_FORM_TOGGLE_DESCRIPTION" />}>
               { "I don't have a lab" }
           </Toggle>
           {
               showSystemLabForm ? (
                   <SystemLabInputForm handleDeviceModelChange={handleDeviceModelChange} />
               ): (
                   <UserLabSelectionForm labFetchError={labFetchError} labOptions={labOptions}
                                         handleLabChange={handleLabChange} selectedOption={selectedOption}
                                         labFetchStatus={labFetchStatus} labInfoError={labInfoError}/>
               )
           }
       </ColumnLayout>
     </FormSection>
     {accountValidationView}
     {!showSystemLabForm && labInfo && labInfo.id && <FeatureFileMappingWrapper />}
   </>
 );
};

const UserLabSelectionForm = ({labFetchError, labOptions, handleLabChange, selectedOption, labFetchStatus, labInfoError}) => {
    return <>
        <div data-awsui-column-layout-root="true">
            {labFetchError}
            <FormField
                label={<FormattedMessage id="LAB" />}
                description={<FormattedMessage id="SELECT_A_LAB" />}
            >
                <Select
                    options={labOptions}
                    onChange={handleLabChange}
                    selectedOption={selectedOption}
                    loading={labFetchStatus === API_STATUS.PENDING}
                    selectedLabel="Selected"
                    invalid={!!labInfoError}
                ></Select>
                {labInfoError ? (
                    <div className="formErrMsg">{labInfoError}</div>
                ) : null}
            </FormField>
        </div>
    </>
}

const SystemLabInputForm = ({handleDeviceModelChange}) => {
    return <>
        <div data-awsui-column-layout-root="true">
            <FormField
                label={<FormattedMessage id="DEVICE_MODEL" />}
                description={<FormattedMessage id="INPUT_DEVICE_MODEL" />}
            >
                <Input
                    placeholder="Device Model Name...'hazel'"
                    onChange={handleDeviceModelChange}
                ></Input>
            </FormField>
        </div>
    </>
}

const FeatureFileMappingWrapper = () => {
 const {
   status: piLoadStatus,
   pis,
   error: piFetchErr,
 } = useSelector(piSelector);
 const { PENDING, ERROR, SUCCESS } = API_STATUS;

 const { testSuite } = useSelector(testInitSelector);

 let actors = [];
 if (testSuite) {
   actors = testSuite.mapping.map((m) => m.actors).flat();
   actors = [...new Set(actors)];
 }
 if (piLoadStatus === PENDING) {
   return (
     <div className="awsui-util-mt-l">
       <Spinner /> <FormattedMessage id="LOADING_RESOURCES" />
     </div>
   );
 }

 const errView = [];

 if (piLoadStatus === ERROR) {
   errView.push(
     <Alert key={2} type="error" className="awsui-util-mt-s awsui-util-mb-s">
       <div>{piFetchErr}</div>
     </Alert>
   );
 }

 if (errView.length) {
   return <>{errView}</>;
 }

 return (
   <>
     <FormSection header={<>Host & Device Configuration</>}>
       {actors.map((dut, ind) => (
         <PiConfigView key={ind} bddName={dut} configType="actor" />
       ))}
     </FormSection>
   </>
 );
};

const PiConfigView = ({ bddName, configType }) => {
 const { pis, status: pisStatus } = useSelector(piSelector);
 const { labInfo } = useSelector(labConfigSelector);
 const { mapping } = useSelector(testMappingSelector);
 const [selectedPi, setSelectedPi] = React.useState({});
 const [isNewLabSelected, setIsNewLabSelected] = React.useState(false);
 const [dutError, setDutError] = React.useState("");
 const [dutSelectionError, setDutSelectionError] = React.useState("");
 const [dutStatus, setDutStatus] = React.useState("");
 const [dutLoading, setDutLoading] = React.useState(false);
 const [connectedDevices, setConnectedDevices] = useState([]);
 const [selectedDevices, setSelectedDevices] = useState([]);
 const dispatch = useDispatch();
 const { testSuite } = useSelector(testInitSelector);

 const handlePiChange = (e) => {
   setDutError("");
   dispatch(resetMapping());
   setDutLoading(true);
   setDutStatus("Fetching connected device...");
   const piInfo = e.detail.selectedOption.meta;
   const labId = labInfo.id;
   let comm_id;
   Controller.runActionOnThing(labId, piInfo.id, AppConstants.rasPiAction.GET_DUTS.id)
   .then((response) => {
    setDutLoading(false);
    if (response.hasOwnProperty("success")
    && response.success.hasOwnProperty("payload")) {
      if (!response.success.payload || Object.keys(response.success.payload).length === 0) {
        setDutError("Test device is not connected to the host machine. Please connect a device and retry.");
        return;
      } else {
          const devices = Object.entries(response.success.payload).map(([key, value]) => ({ key, ...value }));
          Promise.all(devices.map(device => Controller.getDSNDeviceData(device.dsn, piInfo.name)))
              .then(deviceDataList => {
                  const updatedDevices = deviceDataList.map((deviceData, index) => {
                      if (deviceData.success) {
                          return {
                              ...devices[index],
                              inUse: deviceData.success.inUse
                          };
                      } else if (deviceData.error) {
                          return {
                              ...devices[index],
                              inUse: false
                          };
                      } else {
                          setDutError("Unable to fetch status for : " + devices[index].dsn);
                      }
                  });
                  setConnectedDevices(updatedDevices);
              })
      }
    } else {
      setDutError("Unable to fetch connected device details.");
      return;
    }
   setSelectedPi({id : piInfo.id, label: piInfo.name});
   const currMapping = {
     name: piInfo.name,
     configType,
     piMeta: piInfo,
     thingId: piInfo.id,
     piName: piInfo.name,
   };
   dispatch(resetMapping());
   dispatch(addMapping(currMapping));
   if(comm_id){
    dispatchDeviceMapping(piInfo.name, comm_id)
   }
   setIsNewLabSelected(false);
   })
  };

  const handleDeviceSelection = (e, key, device) => {
    const isChecked = e.detail.checked;
    setDutSelectionError(null);
    setSelectedDevices((prevSelectedDevices) => {
      const selectedDeviceKeys = getSelectedDeviceKeys(prevSelectedDevices, isChecked, key);
      let errorMessage;
      if(isChecked) {
         errorMessage = getErrorMessage(prevSelectedDevices, selectedDeviceKeys, key, device);
         setDutSelectionError(errorMessage);
      }
      if (!errorMessage) {
        dispatchDeviceMapping(selectedPi.label, selectedDeviceKeys);
      }
      return selectedDeviceKeys;
    });
  };

  const getSelectedDeviceKeys = (prevSelectedDevices, isChecked, key) => {
    const alreadySelectedDevices = prevSelectedDevices.length > 0;
    if (alreadySelectedDevices) {
      const firstSelectedDevice = connectedDevices.find((dev) => dev.key === prevSelectedDevices[0]);
      const currentDevice = connectedDevices.find((dev) => dev.key === key);
      if (firstSelectedDevice.dtid !== currentDevice.dtid || firstSelectedDevice.build_number !== currentDevice.build_number) {
        return prevSelectedDevices;
      } else {
        return isChecked ? [...prevSelectedDevices, key] : prevSelectedDevices.filter((selectedKey) => selectedKey !== key);
      }
    } else {
      return isChecked ? [key] : [];
    }
  };

  const getErrorMessage = (prevSelectedDevices, selectedDeviceKeys, key, device) => {
    const alreadySelectedDevices = prevSelectedDevices.length > 0;
    if (alreadySelectedDevices) {
      //For FOS we only want single device selection so 'alreadySelectedDevices' should be 0
      if (testSuite.displayName.includes("FOS")) {
        return "Please select only a single device at a time.";
      }
      const firstSelectedDevice = connectedDevices.find((dev) => dev.key === prevSelectedDevices[0]);
      const currentDevice = connectedDevices.find((dev) => dev.key === key);
      if (firstSelectedDevice.dtid !== currentDevice.dtid || firstSelectedDevice.build_number !== currentDevice.build_number) {
        return "All selected devices must have the same Device Type and Build ID.";
      }
    }
    return null;
  };

    const dispatchDeviceMapping = (piName, selectedDeviceKeys) => {
        const devMapping = {
            name: piName,
            configType : "ftvdut",
            dsn: typeof selectedDeviceKeys === 'string' ? selectedDeviceKeys: selectedDeviceKeys.join(','),
            dtid : 'AQRMMA6JEEZF',
            lab : labInfo.name
        }
        dispatch(addMapping(devMapping));
    };

  const renderDeviceSelection = () => {
    if (!dutError && connectedDevices.length > 0) {
      return (
        <>
          <FormField
            label={<FormattedMessage id="CONNECTED_DEVICES" />}
            description={<FormattedMessage id="SELECT_CONNECTED_DEVICES" />}
          >
            {connectedDevices.map((device) => (
              <Checkbox
                key={device.key}
                value={device.key}
                checked={selectedDevices.includes(device.key)}
                onChange={(e) => handleDeviceSelection(e, device.key, device)}
                label={`${device.key} [${device.dtid} : ${device.build_number}]`}
                disabled={device.inUse}
              />
            ))}
          </FormField>
          {dutSelectionError && (
            <Alert type="error" className="awsui-util-mt-s">
              {dutSelectionError}
            </Alert>
          )}
        </>
      );
  }
  return null;
 };

 return (
   <>
       <FormField
         label={<FormattedMessage id="HOST" />}
         description={<FormattedMessage id="SELECT_HOST" />}
       >
         <Select
           options={pis.map((pi) => ({ label: pi.name, id: pi.id, disabled: !pi.connected, meta: pi }))}
           onChange={handlePiChange}
           loading={pisStatus === API_STATUS.PENDING}
           selectedLabel="Selected"
           value = {selectedPi}
           selectedOption={selectedPi}
         />
          {dutLoading ? (<Spinner size="normal"></Spinner>) : ""} {dutStatus ? (<p className='green-color-text'>{dutStatus}</p>) : ""}
          {dutError ? (<p className='red-color-text'>{dutError}</p>) : ""}
       </FormField>
       {renderDeviceSelection()}
   </>
 );
};
